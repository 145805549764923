define("discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note", ["exports", "@ember/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _ajaxError, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.modalIsVisible}}
    <DModal
      @title={{i18n "user_notes.title"}}
      @subtitle={{i18n "user_notes.subtitle"}}
      @closeModal={{fn (mut this.modalIsVisible) false}}
      class="add-user-note-modal"
    >
      <Textarea @value={{this.newNote}} />
      <DButton
        @action={{action "attachNote"}}
        @label="user_notes.attach"
        @disabled={{this.attachDisabled}}
        class="btn-primary"
      />
    </DModal>
  {{/if}}
  */
  {
    "id": "xi9I7X9X",
    "block": "[[[41,[30,0,[\"modalIsVisible\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"add-user-note-modal\"]],[[\"@title\",\"@subtitle\",\"@closeModal\"],[[28,[37,2],[\"user_notes.title\"],null],[28,[37,2],[\"user_notes.subtitle\"],null],[28,[37,3],[[28,[37,4],[[30,0,[\"modalIsVisible\"]]],null],false],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],null,[[\"@value\"],[[30,0,[\"newNote\"]]]],null],[1,\"    \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,7],[[30,0],\"attachNote\"],null],\"user_notes.attach\",[30,0,[\"attachDisabled\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-modal\",\"i18n\",\"fn\",\"mut\",\"textarea\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/components/modal/blizzard-add-user-note.hbs",
    "isStrictMode": false
  });
  class BlizzardAddUserNote extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "newNote", [_tracking.tracked], function () {
      return "";
    }))();
    #newNote = (() => (dt7948.i(this, "newNote"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modalIsVisible", [_tracking.tracked], function () {
      return true;
    }))();
    #modalIsVisible = (() => (dt7948.i(this, "modalIsVisible"), void 0))();
    reset() {
      this.newNote = "";
      this.saving = false;
      this.modalIsVisible = true;
    }
    attachDisabled(newNote, saving) {
      return saving || !newNote || newNote.length === 0;
    }
    static #_3 = (() => dt7948.n(this.prototype, "attachDisabled", [(0, _decorators.default)("newNote", "saving")]))();
    attachNote() {
      const note = this.store.createRecord("user-note");
      this.set("saving", true);
      let args = {
        raw: this.newNote,
        user_id: this.model.user.id
      };
      note.save(args).then(() => {
        this.set("newNote", "");
        if (this.model.callback) {
          this.model.callback();
        }
        this.modalIsVisible = false;
      }).catch(_ajaxError.popupAjaxError).finally(() => this.set("saving", false));
    }
    static #_4 = (() => dt7948.n(this.prototype, "attachNote", [_object.action]))();
  }
  _exports.default = BlizzardAddUserNote;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BlizzardAddUserNote);
});