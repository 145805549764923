define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-boxes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.categories as |c index|}}
    <div class="subcategory-container {{third-class index}}">
      <BSubcategory @category={{c}} />
    </div>
  {{/each}}
  */
  {
    "id": "S8N3BhGi",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[29,[\"subcategory-container \",[28,[37,2],[[30,2]],null]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1,2]],null]],[\"c\",\"index\"],false,[\"each\",\"-track-array\",\"third-class\",\"b-subcategory\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-boxes.hbs",
    "isStrictMode": false
  });
});