define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/user-summary-topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{latest-topic-list-item topic=this.topic url=this.url}}
  */
  {
    "id": "IcJTOx8J",
    "block": "[[[1,[28,[35,0],null,[[\"topic\",\"url\"],[[30,0,[\"topic\"]],[30,0,[\"url\"]]]]]]],[],false,[\"latest-topic-list-item\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/user-summary-topic.hbs",
    "isStrictMode": false
  });
});