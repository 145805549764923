define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/topic-category", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.category}}
    <BCategorySmall @category={{this.topic.category}} />
  {{/if}}
  */
  {
    "id": "7fhONYHR",
    "block": "[[[41,[30,0,[\"topic\",\"category\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@category\"],[[30,0,[\"topic\",\"category\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"b-category-small\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/topic-category.hbs",
    "isStrictMode": false
  });
});