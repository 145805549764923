define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/above-main-container/blizzard-banner-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.silencedTillDate}}
    <div class="b-banner-message silenced-banner">
      {{html-safe
        (i18n "blizzard.silenced_banner" date=this.currentUser.silencedTillDate)
      }}
    </div>
  {{else if this.currentUser.minimum_level_not_met}}
    <div class="b-banner-message minimum-level-not-met">
      {{html-safe (i18n "blizzard.minimum_level_not_met")}}
    </div>
  {{/if}}
  */
  {
    "id": "1MosUSAj",
    "block": "[[[41,[30,0,[\"currentUser\",\"silencedTillDate\"]],[[[1,\"  \"],[10,0],[14,0,\"b-banner-message silenced-banner\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[\"blizzard.silenced_banner\"],[[\"date\"],[[30,0,[\"currentUser\",\"silencedTillDate\"]]]]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"currentUser\",\"minimum_level_not_met\"]],[[[1,\"  \"],[10,0],[14,0,\"b-banner-message minimum-level-not-met\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[\"blizzard.minimum_level_not_met\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"html-safe\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/above-main-container/blizzard-banner-message.hbs",
    "isStrictMode": false
  });
});