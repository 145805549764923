define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.categories}}
    <div class="category-list">
      {{component this.categoryListComponent categories=this.categories}}
    </div>
  {{/if}}
  
  {{#if this.siteSettings.blizzard_realms_enabled}}
    {{b-realms-list categories=this.categories filter="yours"}}
    {{b-realms-list categories=this.categories filter="classic"}}
    {{b-realms-list categories=this.categories filter="all"}}
  {{/if}}
  */
  {
    "id": "xqsVvLdM",
    "block": "[[[41,[30,0,[\"categories\"]],[[[1,\"  \"],[10,0],[14,0,\"category-list\"],[12],[1,\"\\n    \"],[46,[30,0,[\"categoryListComponent\"]],null,[[\"categories\"],[[30,0,[\"categories\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"blizzard_realms_enabled\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"yours\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"classic\"]]]],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"categories\",\"filter\"],[[30,0,[\"categories\"]],\"all\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"component\",\"b-realms-list\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/categories-only.hbs",
    "isStrictMode": false
  });
});