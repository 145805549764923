define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-preferences-account/blizzard-custom-instructions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.canChangeAvatar}}
    <div class="control-group pref-avatar-blizzard">
      <label class="control-label">{{i18n "user.avatar.title"}}</label>
      <div class="controls">
        {{! we want the "huge" version even though we're downsizing it in CSS }}
        {{bound-avatar this.model "huge"}}
        <DButton
          @action={{this.showAvatarSelector}}
          @actionParam={{this.model}}
          @icon="blizzard-account"
          @label="user.change_avatar.label"
        />
      </div>
    </div>
  {{/if}}
  
  <BAccountLink @user="model" />
  
  {{#if this.canChangeCharacter}}
    <div class="control-group pref-avatar-blizzard">
      <label class="control-label">{{i18n "user.change_character.label"}}</label>
      <div class="controls">
        <div class="character">
          {{! we want the "huge" version even though we're downsizing it in CSS }}
          {{bound-avatar this.model "huge"}}
          {{character-details character=this.model.character showName="true"}}
        </div>
        <DButton
          @action={{this.showCharacterSelector}}
          @actionParam={{this.model}}
          @icon="blizzard-account"
          @label="user.change_character.label"
        />
      </div>
    </div>
  {{/if}}
  */
  {
    "id": "UVOJAuOp",
    "block": "[[[41,[30,0,[\"canChangeAvatar\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group pref-avatar-blizzard\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"user.avatar.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[1,\"      \"],[1,[28,[35,2],[[30,0,[\"model\"]],\"huge\"],null]],[1,\"\\n      \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[[30,0,[\"showAvatarSelector\"]],[30,0,[\"model\"]],\"blizzard-account\",\"user.change_avatar.label\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@user\"],[\"model\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"canChangeCharacter\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group pref-avatar-blizzard\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,1],[\"user.change_character.label\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"character\"],[12],[1,\"\\n\"],[1,\"        \"],[1,[28,[35,2],[[30,0,[\"model\"]],\"huge\"],null]],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"character\",\"showName\"],[[30,0,[\"model\",\"character\"]],\"true\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,3],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[[30,0,[\"showCharacterSelector\"]],[30,0,[\"model\"]],\"blizzard-account\",\"user.change_character.label\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"bound-avatar\",\"d-button\",\"b-account-link\",\"character-details\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-preferences-account/blizzard-custom-instructions.hbs",
    "isStrictMode": false
  });
});