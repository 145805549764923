define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-additional-controls/blizzard-card-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BCardButtons @user={{this.user}} @close={{this.close}} />
  */
  {
    "id": "HH8eRy79",
    "block": "[[[8,[39,0],null,[[\"@user\",\"@close\"],[[30,0,[\"user\"]],[30,0,[\"close\"]]]],null]],[],false,[\"b-card-buttons\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-card-additional-controls/blizzard-card-buttons.hbs",
    "isStrictMode": false
  });
});