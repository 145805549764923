define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/molecules/b-category-small", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#styleguide-example title="b-category-small"}}
    <BCategorySmall @category={{get this.dummy.categories 0}} href="#" />
  {{/styleguide-example}}
  */
  {
    "id": "ZhnY069X",
    "block": "[[[6,[39,0],null,[[\"title\"],[\"b-category-small\"]],[[\"default\"],[[[[1,\"  \"],[8,[39,1],[[24,6,\"#\"]],[[\"@category\"],[[28,[37,2],[[30,0,[\"dummy\",\"categories\"]],0],null]]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"b-category-small\",\"get\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/styleguide/molecules/b-category-small.hbs",
    "isStrictMode": false
  });
});