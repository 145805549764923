define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/above-site-header/attach-welcome", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BWelcome @currentPath={{this.currentPath}} @welcome-location="above" />
  */
  {
    "id": "21/p8PT2",
    "block": "[[[8,[39,0],null,[[\"@currentPath\",\"@welcome-location\"],[[30,0,[\"currentPath\"]],\"above\"]],null]],[],false,[\"b-welcome\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/above-site-header/attach-welcome.hbs",
    "isStrictMode": false
  });
});