define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/category-name-fields", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="category-name-fields">
    <section class="field">
      <section class="field-item">
        <label>{{i18n "category.name"}}</label>
        {{category-field-name
          value=this.category.name
          placeholderKey="category.name_placeholder"
          maxlength="50"
          titleKey="category.name_placeholder"
        }}
      </section>
      <section class="field-item">
        <label>{{i18n "category.slug"}}</label>
        {{category-field-name
          value=this.category.slug
          placeholderKey="category.slug_placeholder"
          maxlength="255"
          titleKey="category.slug_placeholder"
        }}
      </section>
    </section>
  </div>
  */
  {
    "id": "HQu2MPue",
    "block": "[[[10,0],[14,0,\"category-name-fields\"],[12],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,0],[\"category.name\"],null]],[13],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"value\",\"placeholderKey\",\"maxlength\",\"titleKey\"],[[30,0,[\"category\",\"name\"]],\"category.name_placeholder\",\"50\",\"category.name_placeholder\"]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,0],[\"category.slug\"],null]],[13],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"value\",\"placeholderKey\",\"maxlength\",\"titleKey\"],[[30,0,[\"category\",\"slug\"]],\"category.slug_placeholder\",\"255\",\"category.slug_placeholder\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"category-field-name\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/category-name-fields.hbs",
    "isStrictMode": false
  });
});