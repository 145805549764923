define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-profile-controls/franchise-profile-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.custom_fields.profile_url}}
    {{d-button
      class="btn-primary user-card-button"
      action="showProfile"
      icon="user"
      label="user.view_profile"
    }}
  {{/if}}
  */
  {
    "id": "6mombEGP",
    "block": "[[[41,[30,0,[\"model\",\"custom_fields\",\"profile_url\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\",\"action\",\"icon\",\"label\"],[\"btn-primary user-card-button\",\"showProfile\",\"user\",\"user.view_profile\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/user-profile-controls/franchise-profile-button.hbs",
    "isStrictMode": false
  });
});