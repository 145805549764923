define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/latest-topic-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Parent element for topic icons, topic name, and reply count }}
  <PluginOutlet @name="above-latest-topic-list-item" @connectorTagName="div" />
  <div class="b-topic-line title-and-reply-count">
    <div class="b-topic-link main-link">
      {{raw "topic-status" topic=this.topic}}
      {{topic-link this.topic}}
    </div>
  
    {{raw "list/posts-count-column" topic=this.topic tagName="div"}}
  </div>
  
  {{! Parent element for category name and the age of the post }}
  <div class="b-topic-line category-and-age">
    {{#if this.topic.category}}
      <BCategorySmall @category={{this.topic.category}} />
    {{/if}}
  
    <a
      href={{this.topic.lastPostUrl}}
      class="b-age"
      title={{this.topic.bumpedAtTitle}}
    >
      {{format-date this.topic.bumpedAt format="tiny" noTitle="true"}}
    </a>
  </div>
  */
  {
    "id": "q3aIz5Qn",
    "block": "[[[8,[39,0],null,[[\"@name\",\"@connectorTagName\"],[\"above-latest-topic-list-item\",\"div\"]],null],[1,\"\\n\"],[10,0],[14,0,\"b-topic-line title-and-reply-count\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"b-topic-link main-link\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"topic-status\"],[[\"topic\"],[[30,0,[\"topic\"]]]]]],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"topic\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],[\"list/posts-count-column\"],[[\"topic\",\"tagName\"],[[30,0,[\"topic\"]],\"div\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"b-topic-line category-and-age\"],[12],[1,\"\\n\"],[41,[30,0,[\"topic\",\"category\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@category\"],[[30,0,[\"topic\",\"category\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,3],[15,6,[30,0,[\"topic\",\"lastPostUrl\"]]],[14,0,\"b-age\"],[15,\"title\",[30,0,[\"topic\",\"bumpedAtTitle\"]]],[12],[1,\"\\n    \"],[1,[28,[35,5],[[30,0,[\"topic\",\"bumpedAt\"]]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"plugin-outlet\",\"raw\",\"topic-link\",\"if\",\"b-category-small\",\"format-date\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/components/latest-topic-list-item.hbs",
    "isStrictMode": false
  });
});