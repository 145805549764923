define("discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/after-panel-body/select-character", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displayButtons}}
    <hr class="select-avatar-separator" />
  {{/if}}
  {{#if this.canChangeAvatar}}
    <div class="select-avatar-link">
      <DButton
        @action={{this.showAvatarSelector}}
        @actionParam={{this.currentUser}}
        @icon="blizzard-account"
        @label="user.change_avatar.label"
      />
    </div>
  {{/if}}
  
  {{#if this.canChangeCharacter}}
    <div class="select-character-link">
      <DButton
        @action={{this.showCharacterSelector}}
        @actionParam={{this.currentUser}}
        @icon="blizzard-account"
        @label="user.change_character.label"
      />
    </div>
  {{/if}}
  */
  {
    "id": "2+RTPI2d",
    "block": "[[[41,[30,0,[\"displayButtons\"]],[[[1,\"  \"],[10,\"hr\"],[14,0,\"select-avatar-separator\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"canChangeAvatar\"]],[[[1,\"  \"],[10,0],[14,0,\"select-avatar-link\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[[30,0,[\"showAvatarSelector\"]],[30,0,[\"currentUser\"]],\"blizzard-account\",\"user.change_avatar.label\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canChangeCharacter\"]],[[[1,\"  \"],[10,0],[14,0,\"select-character-link\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@action\",\"@actionParam\",\"@icon\",\"@label\"],[[30,0,[\"showCharacterSelector\"]],[30,0,[\"currentUser\"]],\"blizzard-account\",\"user.change_character.label\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-blizzard-plugin/discourse/templates/connectors/after-panel-body/select-character.hbs",
    "isStrictMode": false
  });
});